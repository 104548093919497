import React from 'react'; // eslint-disable-line
/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { Global } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Global
        styles={theme => ({
          "*": {
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
          },
          "body.overflowed": {
            height: "100vh",
            overflow: "hidden",
          },
          img: {
            display: "block",
          },
          a: {
            transition: "all .15s ease-in-out",
          },
          "ul, ol": {
            lineHeight: 1.666,
            listStylePosition: "inside",
          },
        })}
      />
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header siteTitle={data.site.siteMetadata.title} />
        <main
          sx={{
            width: "100%",
            flex: "1 1 auto",
          }}
        >
          {children}
        </main>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
