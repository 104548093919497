/** @jsx jsx */
import React, { useState } from 'react'; // eslint-disable-line
import { jsx, Checkbox as CB, Button, Heading, Spinner } from 'theme-ui';
import { useSelector } from 'react-redux';
import { useMutation } from 'graphql-hooks';

import Checkbox from '../components/forms/Checkbox';
import GraphQLForm from '../components/forms/GraphQLForm';
import Wrapper from '../components/Wrapper';
import Dropdown from '../components/forms/Dropdown';
import PageWrap from '../components/PageWrap';
import Table from '../components/Table';
import TextInput from '../components/forms/TextInput';

import { useQueryCustom, useSnackbar } from '../lib/hooks';

import { contact as contactSchema } from '@bit/morleytatro.main.kongrads-schemas';

const query = `
  query($id: Int!) {
    getUserById(id: $id) {
      profile {
        first_name
        last_name
        school
      }
      contacts {
        id
        first_name
        last_name
        email
        phone
        relationship
        last_sent
      }
      stripe {
        stripe_user_id
      }
    }
  }
`;

const createQuery = `
  mutation($userId: Int!, $input: ContactInput!) {
    createContact(userId: $userId, input: $input) {
      contact {
        id
        first_name
        last_name
        email
        phone
        relationship
        last_sent
      }
      sendResult
    }
  }
`;

const bulkSendQuery = `
  mutation($ids: [Int!]!) {
    sendBulkAnnouncement(ids: $ids) {
      emails
      texts
    }
  }
`;

const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  relationship: '',
};

function getFullName({ first_name, last_name }) {
  return [first_name, last_name].join(' ');
}

export default function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [checkedContacts, setCheckedContacts] = useState({});
  const { openSnackbar } = useSnackbar();
  const [sendBulkAnnouncement] = useMutation(bulkSendQuery);
  const user = useSelector(state => state.user);
  const { data, error, loading } = useQueryCustom(query, {
    variables: {
      id: user.id
    },
    onSuccess: data => {
      setContacts(data.getUserById.contacts);
      setCheckedContacts(data.getUserById.contacts.reduce((acc, contact) => ({
        ...acc,
        [contact.id]: false
      }), {}));
    }
  });

  const addContact = data => {
    const { contact } = data.createContact;
    setContacts(current => [...current, contact]);
    setCheckedContacts(current => ({
      ...current,
      [contact.id]: false
    }));

    openSnackbar(
      <>
        <p>Contact successfully added!</p>
        {data.createContact.sendResult.length > 0 && (
          <ul>
            {data.createContact.sendResult.map((result, i) => (
              <li key={i}>{result}</li>
            ))}
          </ul>
        )}
      </>
    )
  }

  function toggleChecked(id) {
    setCheckedContacts(current => ({
      ...current,
      [id]: !current[id]
    }));
  }

  function handleBulkSend() {
    sendBulkAnnouncement({ variables: {
      ids: Object.entries(checkedContacts)
        .filter(entry => entry[1])
        .map(entry => +entry[0])
    } })
      .then(({ data }) => {
        const { emails, texts } = data.sendBulkAnnouncement;

        openSnackbar(<ul>
          <li>{emails}</li>
          <li>{texts}</li>
        </ul>)
      })
      .catch(() => openSnackbar('Something went wrong. Please try again.'))
  }

  let inner = <Spinner/>

  if(!loading && !error) {
    const { profile, stripe } = data.getUserById;

    const { first_name, last_name, school } = profile;

    const canSend = stripe !== null;
  
    const name = [first_name, last_name].join(' ');
  
    const contactsArray = contacts
      .slice()
      .sort((a, b) => getFullName(a) - getFullName(b))
      .map(contact => ({
        ...contact,
        last_sent: contact.last_sent ? new Date(+contact.last_sent).toLocaleDateString() : '',
        send: (
          // eslint-disable-next-line
          <label style={{ cursor: 'pointer' }}>
            <CB onClick={() => toggleChecked(contact.id)}/>
          </label>
        )
      }));
    
    const checkboxLabel = 'Send announcement now' + (canSend
      ? ''
      : ' (requires connected Stripe account)');
    
    const numberChecked = Object
      .values(checkedContacts)
      .filter(val => val)
      .length;
    
    function handleCheckBoxClick(e) {
      if(!canSend) {
        e.preventDefault();
        openSnackbar('You must return to the settings page and connect to Stripe before you can send out your announcement.');
      }
    }
    
    inner = (
      <>
        <GraphQLForm
          validationSchema={contactSchema}
          defaultValues={{
            ...defaultValues,
            send: canSend
          }}
          query={createQuery}
          onSuccess={addContact}
          mergeVariables={{ userId: user.id }}
          buttonText="Add Contact"
          resetOnSuccess={true}
          // successMessage="Contact successfully added!"
        >
          <TextInput
            name="first_name"
            label="First Name"
          />
          <TextInput
            name="last_name"
            label="Last Name"
          />
          <TextInput
            name="email"
            label="Email"
            type="email"
          />
          <TextInput
            name="phone"
            label="Mobile"
            type="number"
          />
          <Dropdown
            name="relationship"
            label="Relationship"
            emptyOption={true}
            options={[
              {
                label: 'Parent',
                value: 'PARENT'
              },
              {
                label: 'Grandparent',
                value: 'GRANDPARENT'
              },
              {
                label: 'Aunt/Uncle',
                value: 'AUNTUNCLE'
              },
              {
                label: 'Sibling',
                value: 'SIBLING'
              },
              {
                label: 'Other',
                value: 'OTHER'
              },
            ]}
          />
          <Checkbox
            label={checkboxLabel}
            name="send"
            disabled={!canSend}
            handleClick={handleCheckBoxClick}
          />
        </GraphQLForm>
        <Wrapper sx={{ mt: 6 }}>
          <Heading as="h3" sx={{ textAlign: "center", mb: 5 }}>
            Here is what your message will say:
          </Heading>
          <div
            sx={{
              p: [3, 4],
              background: "white",
              borderRadius: "3px",
              boxShadow: "0 3px 10px 0 rgba(76, 82, 103, 0.1)",
            }}
          >
            <p>Dear {'{{CONTACT NAME}}'},</p>
            <p>
              {name || '{{YOUR NAME}}'} is graduating from {school || '{{SCHOOL}}'}.
              This year {school || '{{SCHOOL}}'} will not have its traditional ceremony, but {name || '{{YOUR NAME}}'} is still
              graduating none the less.
            </p>
            <p>
              Help celebrate this important milestone.
            </p>
          </div>
        </Wrapper>
        <Wrapper sx={{ mt: 6 }}>
          <div sx={{ marginBottom: '20px' }}>
            <Button
              disabled={!canSend || numberChecked === 0}
              onClick={handleBulkSend}
            >
              Send to {numberChecked}
            </Button>
          </div>
          <Table
            headers={[
              {
                name: 'send',
                label: 'Send'
              },
              {
                name: 'first_name',
                label: 'First Name'
              },
              {
                name: 'last_name',
                label: 'Last Name'
              },
              {
                name: 'email',
                label: 'Email'
              },
              {
                name: 'phone',
                label: 'Phone'
              },
              {
                name: 'relationship',
                label: 'Relationship'
              },
              {
                name: 'last_sent',
                label: 'Last Emailed'
              }
            ]}
            rows={contactsArray}
          />
        </Wrapper>
      </>
    );
  }

  return (
    <PageWrap title="Contacts">
      {inner}
    </PageWrap>
  )
}