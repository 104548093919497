import React from 'react';

import { Spinner } from 'theme-ui';
import { useQuery } from 'graphql-hooks';
import { useSelector } from 'react-redux';

import Wrapper from '../components/Wrapper';
import PageWrap from '../components/PageWrap';
import Table from '../components/Table';

import { formatMoney } from '../lib/utilities';

const query = `
  query($userId: Int!) {
    getGiftsByUserId(userId: $userId) {
      id
      amount
      fee
      last_name
      email
      created_at
    }
  }
`;

function mapTransaction(trans) {
  return {
    ...trans,
    amount: formatMoney(trans.amount / 100),
    created_at: new Date(+trans.created_at)
      .toLocaleString()
  };
}

export default function Gifts() {
  const user = useSelector(state => state.user);
  const { loading, data } = useQuery(query, {
    variables: {
      userId: user.id
    }
  });

  let inner = <Spinner/>

  if(!loading) {
    inner = (
      <Table
        headers={[
          {
            name: 'id',
            label: 'ID'
          },
          {
            name: 'amount',
            label: 'Amount'
          },
          {
            name: 'email',
            label: 'Sender'
          },
          {
            name: 'created_at',
            label: 'Date / Time'
          }
        ]}
        rows={data.getGiftsByUserId.map(mapTransaction)}
      />
    );
  }

  return (
    <PageWrap title="Gifts">
      <Wrapper>
        {inner}
      </Wrapper>
    </PageWrap>
  );
}