/** @jsx jsx */
import { jsx, Heading } from "theme-ui"

export default props => {
  const { children, bg, title, subtitle, ...restProps } = props
  return (
    <section
      sx={{
        ...(bg && { background: bg }),
        p: ["40px 0", "60px 0", "75px 0"],
      }}
      {...restProps}
    >
      {(title || subtitle) && (
        <div sx={{ mb: 5, px: 3, variant: "centeredInner" }}>
          {title && (
            <Heading as="h3" sx={{ fontSize: "30px", mb: 2 }}>
              {title}
            </Heading>
          )}
          {subtitle && <p sx={{ fontSize: "18px", m: 0 }}>{subtitle}</p>}
        </div>
      )}
      {props.children}
    </section>
  )
}
