/** @jsx jsx */
import { jsx, Spinner } from 'theme-ui';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { useQuery } from 'graphql-hooks';

import GraphQLForm from '../components/forms/GraphQLForm';
import TextInput from '../components/forms/TextInput';
// import Dropdown from '../components/forms/Dropdown';
import PageWrap from '../components/PageWrap';

import { transformDate } from '../lib/utilities';

import { profile as profileSchema } from '@bit/morleytatro.main.kongrads-schemas';

const fieldsToGet = `
  first_name
  last_name
  phone
  school
  grad_date
  profile_pic
`;

const query = `
  query($id: Int!) {
    getProfileByUserId(userId: $id) {
      ${fieldsToGet}
    }
  }
`;

const createQuery = `
mutation($userId: Int!, $input: ProfileInput!) {
  createProfile(userId: $userId, input: $input) {
    ${fieldsToGet}
  }
}
`;

const updateQuery = `
  mutation($userId: Int!, $input: ProfileInput!) {
    updateProfile(userId: $userId, input: $input) {
      ${fieldsToGet}
    }
  }
`

const defaultValues = {
  // prefix: 'NONE',
  first_name: '',
  last_name: '',
  // suffix: 'NONE',
  phone: '',
  // date_of_birth: '',
  school: '',
  grad_date: '',
  profile_pic: ''
};

export default function Profile() {
  const user = useSelector(state => state.user);
  const { data, error, loading } = useQuery(query, {
    variables: {
      id: user.id
    }
  });

  let inner = <Spinner/>

  if(!loading && !error) {
    const { getProfileByUserId: profile } = data;

    const values = profile
      ? {
          ...profile,
          date_of_birth: profile.date_of_birth ? transformDate(profile.date_of_birth) : '',
          grad_date: profile.grad_date ? transformDate(profile.grad_date) : '',
        }
      : defaultValues;

    inner = (
      <GraphQLForm
        defaultValues={values}
        validationSchema={profileSchema}
        mergeVariables={{ userId: user.id }}
        query={profile ? updateQuery : createQuery}
        buttonText="Update profile"
        successMessage="Profile successfully updated!"
        onSuccess={() => navigate('/app/settings')}
      >
        {/* {profile && <p><Link to={'/grads/some-slug/' + user.id}>View your public profile</Link></p>} */}
        {/* <Dropdown
          label="Prefix"
          name="prefix"
          options={[
            {
              label: 'None',
              value: 'NONE'
            },
            {
              label: 'Mr.',
              value: 'MR'
            },
            {
              label: 'Mrs.',
              value: 'MRS'
            },
            {
              label: 'Ms.',
              value: 'MS'
            }
          ]}
        /> */}

        <TextInput
          name="first_name"
          label="First Name"
        />
        <TextInput
          name="last_name"
          label="Last Name"
        />
        {/* <Dropdown
          label="Suffix"
          name="suffix"
          options={[
            {
              label: 'None',
              value: 'NONE'
            },
            {
              label: 'Jr.',
              value: 'JR'
            },
            {
              label: 'Sr.',
              value: 'SR'
            },
            {
              label: 'II',
              value: 'II'
            },
            {
              label: 'III',
              value: 'III'
            },
            {
              label: 'IV',
              value: 'IV'
            },
          ]}
        /> */}
        <TextInput
          type="number"
          name="phone"
          label="Mobile"
        />
        {/* <TextInput
          type="date"
          name="date_of_birth"
          label="Date of Birth"
        /> */}
        <TextInput
          name="school"
          label="Name of School"
        />
        <TextInput
          type="date"
          name="grad_date"
          label="Date of Graduation"
        />
        <TextInput
          name="profile_pic"
          label="Profile Pic URL"
        />
      </GraphQLForm>
    )
  }

  return (
    <PageWrap title="Profile">
      {inner}
    </PageWrap>
  )
}