/** @jsx jsx */
import { jsx, Container } from "theme-ui"

export default props => {
  const { children, ...restProps } = props
  return (
    <Container sx={{ px: 3 }} {...restProps}>
      {props.children}
    </Container>
  )
}
