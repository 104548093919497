import Profile from '../app/Profile';
import Contacts from '../app/Contacts';
import Settings from '../app/Settings';
import Gifts from '../app/Gifts';

export default [
  {
    path: 'profile',
    component: Profile,
    label: 'Profile'
  },
  {
    path: 'contacts',
    component: Contacts,
    label: 'Contacts'
  },
  {
    path: 'settings',
    component: Settings,
    label: 'Settings'
  },
  {
    path: 'gifts',
    component: Gifts,
    label: 'Gifts'
  },
]