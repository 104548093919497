/** @jsx jsx */
import { jsx, Heading } from "theme-ui"

import Section from "./section"
import Link from "./link"
import Wrapper from './Wrapper';
import logo from "../images/logo.svg"

const menuItems = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "Features",
    url: "/features",
  },
  {
    label: "Pricing",
    url: "/pricing",
  },
  {
    label: "FAQ",
    url: "/faq",
  },
  {
    label: "Terms and Conditions",
    url: "/terms-and-conditions",
  },
  {
    label: "Privacy Policy",
    url: "/privacy-policy",
  },
  {
    label: "Refund Policy",
    url: "/refund-policy",
  },
]

export default ({ siteTitle }) => {
  return (
    <footer
      sx={{
        backgroundColor: "dark",
        color: "gray",
      }}
    >
      <Section>
        <Wrapper
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          <div
            sx={{
              display: "flex",
              flexBasis: ["100%", "100%", "33.33333%"],
              flexShrink: 0,
              mb: ["40px", "45px", 0],
              pr: [0, 0, 3],
            }}
          >
            <Link
              to="/"
              sx={{
                textDecoration: "none",
                display: "block",
              }}
            >
              <img
                src={logo}
                alt={siteTitle}
                title={siteTitle}
                sx={{
                  display: "block",
                  width: "100%",
                  maxWidth: ["100px", "120px", "160px"],
                }}
              />
            </Link>
          </div>
          <div
            sx={{
              flexBasis: ["100%", "40%", "33.33333%"],
              flexShrink: 0,
              mb: ["45px", 0],
            }}
          >
            <Heading as="h4" sx={{ color: "grayLight", fontSize: 3, mb: 4 }}>
              Company
            </Heading>
            <nav sx={{ display: "flex", flexDirection: "column" }}>
              {menuItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.url}
                  activeClassName="active"
                  sx={{
                    border: 0,
                    color: "gray",
                    display: "inline-block",
                    textDecoration: "none",
                    "& + &": {
                      marginTop: "12px",
                    },
                    "@media(hover: hover)": {
                      ":hover, :focus": {
                        color: "grayLight",
                      },
                    },
                  }}
                >
                  {item.label}
                </Link>
              ))}
            </nav>
          </div>
          {/* <div sx={{ flexBasis: ["100%", "60%", "33.33333%"], flexShrink: 0 }}>
            <Heading as="h4" sx={{ color: "grayLight", fontSize: 3, mb: 4 }}>
              Subscribe
            </Heading>
            <p sx={{ mt: 0, mb: 4 }}>
              Stay up to date with our latest developments.
            </p>
            <SubscribeForm />
          </div> */}
        </Wrapper>
      </Section>
      <section
        sx={{
          textAlign: "center",
          borderTop: "1px solid",
          borderColor: "grayDark",
          py: ["30px"],
          color: "gray",
        }}
      >
        &copy; Kongrads. All rights reserved.
      </section>
    </footer>
  )
}