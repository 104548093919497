/** @jsx jsx */
import React from 'react'; // eslint-disable-line
import { jsx, Button } from "theme-ui"
import { useState, useEffect, useRef } from "react"
import { Transition } from "react-transition-group"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'graphql-hooks';
import { navigate } from '@reach/router';
import { alpha } from '@theme-ui/color';

import Wrapper from "./Wrapper"
import Link from "./link"
import useOutsideClick from "../lib/useOutsideClick"
import logo from "../images/logo.svg"

import protectedRoutes from '../lib/protected-routes';

const menuItems = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "Features",
    url: "/features",
  },
  {
    label: "Pricing",
    url: "/pricing",
  },
  {
    label: "FAQ",
    url: "/faq",
  },
]

const menuIconStyles = {
  bg: "dark",
  borderRadius: "1px",
  height: "2px",
  marginTop: "-1px",
  marginLeft: "-12px",
  position: "absolute",
  right: 0,
  width: "30px",
  top: "50%",
}

const menuIconPseudoStyles = {
  bg: "dark",
  borderRadius: "1px",
  content: '""',
  height: "100%",
  left: 0,
  position: "absolute",
  width: "100%",
}

const buttonStyles = {
  textDecoration: 'none',
  cursor: "pointer",
  width: ["100%", "100%", "auto"],
  height: ["48px", "48px", "auto"],
  variant: "buttons.primary"
}

function HeaderLi({ children }) {
  return (
    <li
      sx={{
        width: ["100%", "100%", "auto"],
        ml: [0, 0, "30px"],
        mt: ["30px", "30px", 0],
      }}
    >
      {children}
    </li>
  );
}

const query = `
  mutation {
    logout {
      message
    }
  }
`;

const HeaderNav = ({ menuItems, toggleMenuHandler, ...restProps }) => {
  const loaded = useSelector(state => state.loaded);
  const user = useSelector(state => state.user);
  const [logout] = useMutation(query);

  const dispatch = useDispatch();

  async function handleLogout() {
    await logout();
    navigate('/app/login');
    dispatch({
      type: 'SET_USER',
      user: null
    });
  }

  const toggle = loaded && user !== null
    ? 1
    : 0;
  
  let items = menuItems;

  if(toggle) {
    items = protectedRoutes.map(route => ({
      label: route.label,
      url: '/app/' + route.path
    }));
  }

  return (
    <nav {...restProps}>
      <ul
        sx={{
          listStyle: "none",
          p: 0,
          mb: 0,
          mt: ["30px", "30px", 0],
          display: "flex",
          flexDirection: ["column", "column", "row"],
          alignItems: "center",
          borderStyle: "solid",
          borderWidth: ["1px 0 0 0", "1px 0 0 0", 0],
          borderColor: "athensGray",
        }}
      >
        {items.map((item, index) => (
          // eslint-disable-next-line
          <li
            key={index}
            onClick={() => toggleMenuHandler(false)}
            sx={{
              width: ["100%", "100%", "auto"],
              "& + &": {
                marginLeft: [0, 0, "30px"],
              },
              borderStyle: "solid",
              borderWidth: ["0 0 1px 0", "0 0 1px 0", 0],
              borderColor: "grayLight",
            }}
          >
            <Link
              to={item.url}
              activeClassName="active"
              sx={{
                border: 0,
                color: "dark",
                display: "inline-block",
                textDecoration: "none",
                py: ["15px", "15px", 0],
                width: ["100%", "100%", "auto"],
                "&.active": {
                  color: ["dark", "dark", "gray"],
                },
                "@media(hover: hover)": {
                  ":hover, :focus": {
                    color: "gray",
                  },
                },
              }}
            >
              {item.label}
            </Link>
          </li>
        ))}
        {toggle ? (
          <HeaderLi>
            <button
              sx={buttonStyles}
              onClick={handleLogout}
            >
              Log Out
            </button>
          </HeaderLi>
        ) : (
          <>
            <HeaderLi>
              <Link to="/app/login">
                <button sx={buttonStyles}>
                  Log In
                </button>
              </Link>
            </HeaderLi>
            <HeaderLi>
              <Link to="/app/signup">
                <button sx={buttonStyles}>
                  Sign Up
                </button>
              </Link>
            </HeaderLi>
          </>
        )}
      </ul>
    </nav>
  );
}

const MobileMenu = ({
  isMenuOpened,
  toggleMenuHandler,
  menuItems,
  ...restProps
}) => {
  const ref = useRef()
  useOutsideClick(ref, () => {
    toggleMenuHandler(false)
  })
  return (
    <nav
      ref={ref}
      sx={{
        position: "fixed",
        bg: "white",
        height: "100vh",
        right: "-80vw",
        top: 0,
        transition: "right .3s ease-in-out",
        width: "80vw",
        zIndex: 999,
        p: "20px 15px",
        display: ["flex", "flex", "none"],
        flexDirection: "column",
      }}
      {...restProps}
    >
      <button
        onClick={() => toggleMenuHandler(false)}
        sx={{
          alignSelf: "flex-end",
          background: "none",
          border: 0,
          boxShadow: "none",
          height: "30px",
          padding: 0,
          position: "relative",
          width: "30px",
          ":focus": {
            outline: "none",
          },
        }}
      >
        <span
          sx={{
            border: 0,
            clip: "rect(1px, 1px, 1px, 1px)",
            height: "1px",
            overflow: "hidden",
            padding: 0,
            position: "absolute !important",
            width: "1px",
          }}
        >
          Close Menu
        </span>
        <span
          aria-hidden="true"
          sx={{
            ...menuIconStyles,
            background: "none",
            marginLeft: "-17px",
            width: "34px",
            ":before, :after": {
              ...menuIconPseudoStyles,
              top: 0,
            },
            ":before": {
              transform: "rotate(45deg)",
            },
            ":after": {
              transform: "rotate(-45deg)",
            },
          }}
        />
      </button>
      <HeaderNav
        menuItems={menuItems}
        sx={{ display: ["block", "block", "none"] }}
        toggleMenuHandler={toggleMenuHandler}
      />
    </nav>
  )
}

const Header = ({ siteTitle }) => {
  const [isScrolled, setScrolled] = useState(false)
  const [isMenuOpened, setMenuOpened] = useState(false)

  const toggleMenuHandler = flag => {
    if (flag) {
      document.body.classList.add("overflowed")
    } else {
      document.body.classList.remove("overflowed")
    }
    setMenuOpened(flag)
  }

  useEffect(() => {
    let offsetY = 0
    let ticking = false

    const handleHeader = scrollPos => {
      setScrolled(scrollPos > 0)
    }

    window.addEventListener("scroll", function (e) {
      offsetY = window.scrollY
      if (!ticking) {
        window.requestAnimationFrame(function () {
          handleHeader(offsetY)
          ticking = false
        })
        ticking = true
      }
    })
  }, [])

  const duration = 300

  const defaultStyle = {
    transition: `right ${duration} ease-in-out`,
    right: "-80vw",
  }

  const transitionStyles = {
    entering: { right: "-80vw" },
    entered: { right: 0 },
    exiting: { right: "-80vw" },
    exited: { right: "-80vw" },
  }

  return (
    <header
      sx={{
        background: "white",
        position: "sticky",
        top: 0,
        zIndex: 999,
        py: [2, 3],
        transition: "box-shadow .15s ease-in-out",
        boxShadow: isScrolled ? "0 1px 4px 0 rgba(76, 82, 103, 0.1)" : "none",
        overflowY: isMenuOpened ? "visible" : "auto",
        borderWidth: "0 0 1px 0",
        borderStyle: "solid",
        borderColor: alpha("grayLight", 0.5),
        ":before": {
          display: [
            isMenuOpened ? "block" : "none",
            isMenuOpened ? "block" : "none",
            "none",
          ],
          position: "absolute",
          content: '""',
          backgroundColor: "rgba(247, 249, 251, 0.75)",
          width: "100%",
          height: "100vh",
          left: 0,
          top: 0,
          zIndex: 998,
          visibility: isMenuOpened ? "visible" : "hidden",
          opacity: isMenuOpened ? 1 : 0,
          transition: "opacity .15s ease-in-out,visibility 0s ease-in-out .15s",
          ...(isMenuOpened && {
            transitionDelay: "0s",
          }),
        },
      }}
    >
      <Wrapper
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          to="/"
          sx={{
            textDecoration: "none",
            display: "block",
          }}
        >
          <img
            src={logo}
            alt={siteTitle}
            title={siteTitle}
            sx={{
              display: "block",
              width: "100%",
              maxWidth: ["100px", "160px", "200px"],
            }}
          />
        </Link>
        <HeaderNav
          menuItems={menuItems}
          sx={{ display: ["none", "none", "block"] }}
          toggleMenuHandler={toggleMenuHandler}
        />
        <Button
          role="button"
          onClick={() => toggleMenuHandler(true)}
          sx={{
            background: "none",
            border: 0,
            boxShadow: "none",
            height: "30px",
            padding: 0,
            position: "relative",
            width: "30px",
            display: ["block", "block", "none"],
            ":focus": {
              outline: "none",
            },
          }}
        >
          <span
            sx={{
              border: 0,
              clip: "rect(1px, 1px, 1px, 1px)",
              height: "1px",
              overflow: "hidden",
              padding: 0,
              position: "absolute !important",
              width: "1px",
            }}
          >
            Open Menu
          </span>
          <span
            aria-hidden="true"
            sx={{
              ...menuIconStyles,
              ":before, :after": {
                ...menuIconPseudoStyles,
              },
              ":before": {
                top: "-8px",
              },
              ":after": {
                bottom: "-8px",
              },
            }}
          />
        </Button>
      </Wrapper>
      <Transition
        in={isMenuOpened}
        timeout={{
          appear: 0,
          enter: 0,
          exit: 300,
        }}
        unmountOnExit={true}
        appear={true}
      >
        {state => (
          <MobileMenu
            isMenuOpened={isMenuOpened}
            toggleMenuHandler={toggleMenuHandler}
            menuItems={menuItems}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          />
        )}
      </Transition>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
