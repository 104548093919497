/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useFormContext } from 'react-hook-form';
import { nanoid } from 'nanoid';

import InputWrap from './InputWrap';

export default ({
  label,
  name,
  type = 'text'
}) => {
  const {
    errors,
    register,
  } = useFormContext();

  const error = errors.hasOwnProperty(name) && errors[name].message;

  const stylesObj = {
    variant: 'forms.input'
  };

  if(error) {
    stylesObj.borderColor = 'red';
  }

  const id = nanoid();

  return (
    <InputWrap
      label={label}
      name={name}
      id={id}
    >
      {/* eslint-disable-next-line */}
      <input
        id={id}
        name={name}
        type={type}
        sx={stylesObj}
        ref={register}
      />
    </InputWrap>
  );
}