/** @jsx jsx */
import React, { useState } from 'react'; // eslint-disable-line
import { Button, jsx } from 'theme-ui';
import { useQuery, useManualQuery } from 'graphql-hooks';
import { Spinner } from 'theme-ui';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';

// import Checkbox from '../components/forms/Checkbox';
// import GraphQLForm from '../components/forms/GraphQLForm';
import PageWrap from '../components/PageWrap';
import Wrapper from '../components/Wrapper';

import { useSnackbar } from '../lib/hooks';

// import { settings as settingsSchema } from '@bit/morleytatro.main.kongrads-schemas';

const query = `
  query($userId: Int!) {
    getUserById(id: $userId) {
      stripe {
        stripe_user_id
      }
    }
  }
`;

// const createQuery = `
//   mutation($userId: Int!, $input: SettingsInput!) {
//     createSettings(userId: $userId, input: $input) {
//       is_public
//     }
//   }
// `;

// const updateQuery = `
//   mutation($userId: Int!, $input: SettingsInput!) {
//     createSettings(userId: $userId, input: $input) {
//       is_public
//     }
//   }
// `;

const stripeConnectQuery = `
  mutation {
    getStripeConnectUrl {
      url
    }
  }
`;

const stripeDisconnectQuery = `
  mutation {
    disconnectFromStripe {
      message
    }
  }
`;

// const defaultValues = {
//   // autopay: false,
//   is_public: false
// };

export default function Settings() {
  const [disconnected, setDisconnected] = useState(false);
  const user = useSelector(state => state.user);
  const { data, error, loading } = useQuery(query, {
    variables: {
      userId: user.id
    }
  });
  const [getStripeConnectUrl] = useManualQuery(stripeConnectQuery);
  const [disconnectFromStripe] = useManualQuery(stripeDisconnectQuery);
  const { openSnackbar } = useSnackbar();

  function authorizeStripe(e) {
    getStripeConnectUrl()
      .then(response => {
        window.location.assign(response.data.getStripeConnectUrl.url);
      });
  }

  function deauthorizeStripe() {
    const confirmed = window.confirm('Are you sure? You will not be able to receive gifts if you do.');

    if(confirmed) {
      disconnectFromStripe()
        .then(({ data }) => {
          if(data.disconnectFromStripe.message === 'Acknowledged') {
            setDisconnected(true);
            openSnackbar('You have successfully disconnected your Stripe account.');
          } else {
            openSnackbar('Something went wrong; please try again.');
          }
        });
    }

  }

  let inner = <Spinner/>

  if(!loading && !error) {
    const { stripe } = data.getUserById;

    inner = stripe && !disconnected ? (
      <>
        <Button
          onClick={deauthorizeStripe}
          variant="warning"
        >
          Disconnect Stripe Account
        </Button>
        <div style={{ marginTop: '20px' }}>
          <Button
            variant="buttons.primary"
            onClick={() => navigate('/app/contacts')}
          >
            Add Contacts
          </Button>
        </div>
      </>
    ) : (
      <>
        <p>In order to have your graduation donations transferred to your account, you must first sign up with Stripe Connect. Please click button below.</p>
        <Button
          type="button"
          onClick={authorizeStripe}
        >
          Connect to Stripe
        </Button>
      </>
    )
  }

  return (
    <PageWrap title="Settings">
      <Wrapper
        sx={{ display: "flex", justifyContent: "center", maxWidth: "640px" }}
      >
        <div
          sx={{
            width: "100%",
            p: [3, 4],
            backgroundColor: "white",
            borderRadius: "3px",
            boxShadow: "0 3px 10px 0 rgba(76, 82, 103, 0.1)",
            "[data-reach-menu-button]": {
              variant: "forms.input",
            },
          }}
        >
          {inner}
        </div>
      </Wrapper>
    </PageWrap>
  );
}