/** @jsx jsx */
import { jsx, Label } from 'theme-ui';
import { useFormContext } from 'react-hook-form';

export default ({
  label,
  name,
  id,
  children
}) => {
  const { errors } = useFormContext();

  const error = errors.hasOwnProperty(name) && errors[name].message;

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: ['column', 'column', 'row'],
        alignItems: ['stretch', 'stretch', 'flex-start'],
        '& + &': {
          mt: 4,
        },
      }}
    >
      <Label
        sx={{
          flexBasis: ["100%", "100%", "160px"],
          flexShrink: 0,
          mt: [0, 0, 1],
        }}
        htmlFor={id}
      >
        {label}
      </Label>
      <div
        sx={{
          flexBasis: ["100%", "100%", "auto"],
          flexGrow: 1,
          pl: [0, 0, 5],
          mt: [2, 2, 0],
        }}
      >
        {children}
        {error && (
          <p sx={{ variant: 'forms.error' }}>{error}</p>
        )}
      </div>
    </div>
  );
}