/** @jsx jsx */
import { jsx } from 'theme-ui';

import Section from './section';

export default function PageWrap({ title, children }) {
  return (
    <Section title={title}>
      {children}
    </Section>
  );
}