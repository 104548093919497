/** @jsx jsx */
import { useState } from 'react';
import { jsx, Button } from 'theme-ui';
import { useMutation } from 'graphql-hooks';
import { FormContext, useForm } from 'react-hook-form';

import Wrapper from '../Wrapper';

import { useSnackbar } from '../../lib/hooks';

export default function GraphQLForm({
  children,
  defaultValues,
  validationSchema,
  query: gqlQuery,
  preSubmit = null,
  onSuccess,
  buttonText = null,
  successMessage = null,
  mergeVariables = {},
  resetOnSuccess = false,
  wrapped = true
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [query] = useMutation(gqlQuery);
  const { openSnackbar } = useSnackbar();

  const methods = useForm({
    defaultValues,
    validationSchema
  });

  async function onSubmit(values) {
    // return console.log(values)
    setIsSubmitting(true);

    let response = false;

    if(typeof preSubmit === 'function') {
      preSubmit(values);
    }

    response = await query({
      variables: {
        input: values,
        ...mergeVariables
      }
    });

    // await completeAction(async() => {
    //   const variables = { input: values }

    //   if(typeof preSubmit === 'function') {
    //     await preSubmit(variables);
    //   }

    //   if(queryType === 'update') {
    //     variables.id = id;
    //   }
  
    //   response = await query({ variables });

    //   if(showSuccessMessage) {
    //     return `${resource} successfully ${queryType}d!`;
    //   }
    // });

    if(response.hasOwnProperty('error')) {
      // open the snackbar
      openSnackbar(response.error.graphQLErrors.map(({ message }, i) => (
        <p key={i} style={{ color: 'red' }}>{message}</p>
      )));
    }

    setIsSubmitting(false);

    if(!response.hasOwnProperty('error')) {
      if(resetOnSuccess) {
        methods.reset();
      }

      if(successMessage) {
        openSnackbar(successMessage);
      }
  
      if(typeof onSuccess === 'function') {
        onSuccess(response.data);
      }
    }
  }

  const form = (
    <form
      onSubmit={methods.handleSubmit(onSubmit)}
      noValidate
    >
      {children}
      <div sx={{ mt: 5, display: "flex", justifyContent: "flex-end" }}>
        <Button>{isSubmitting ? 'Submitting...' : (buttonText || 'Submit')}</Button>
      </div>
    </form>
  );

  return (
    <FormContext {...methods}>
      {wrapped ? (
        <Wrapper
          sx={{ display: "flex", justifyContent: "center", maxWidth: "640px" }}
        >
          <div
            sx={{
              width: "100%",
              p: [3, 4],
              backgroundColor: "white",
              borderRadius: "3px",
              boxShadow: "0 3px 10px 0 rgba(76, 82, 103, 0.1)",
              "[data-reach-menu-button]": {
                variant: "forms.input",
              },
            }}
          >
            {form}
          </div>
        </Wrapper>
      ) : form}
    </FormContext>
  );
}