/** @jsx jsx */
import { jsx } from 'theme-ui';

export default function Table({ headers, rows }) {
  return (
    <table
      sx={{
        variant: "tables.striped",
      }}
    >
      <thead>
        <tr>
          {headers.map((header, i) => (
            <th key={i}>{header.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map(item => (
          <tr key={item.id}>
            {headers.map(header => (
              <td key={header.name}>{item[header.name] || ''}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}