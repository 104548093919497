/** @jsx jsx */
import { jsx, Label, Checkbox } from 'theme-ui';
import { useFormContext } from 'react-hook-form';

export default ({ label, name, ...rest }) => {
  const { register } = useFormContext();

  return (
    <div sx={{ variant: 'forms.checkboxWrapper' }}>
      <Label sx={{ variant: 'forms.checkboxLabel' }} onClick={rest.handleClick || null}>
        <Checkbox
          ref={register}
          name={name}
          sx={{ variant: 'forms.checkbox' }}
          {...rest}
        />
        <p>
          {label}
        </p>
      </Label>
    </div>
  );
}