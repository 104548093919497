/** @jsx jsx */
import { jsx, Select } from 'theme-ui';
import { useFormContext } from 'react-hook-form';
import { nanoid } from 'nanoid';

import InputWrap from './InputWrap';

export default ({ label, name, options, emptyOption = false }) => {
  const { /*errors,*/ register } = useFormContext();

  const id = nanoid();

  // const error = errors.hasOwnProperty(name) && errors[name].message;

  return (
    <InputWrap
      label={label}
      id={id}
      name={name}
    >
      <Select name={name} ref={register} id={id}>
        {emptyOption && (
          <option value="">Please select</option>
        )}
        {options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </Select>
    </InputWrap>
  );
}