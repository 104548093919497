import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useManualQuery } from 'graphql-hooks';

export function useSnackbar() {
  const dispatch = useDispatch();

  return {
    openSnackbar(content) {
      dispatch({
        type: 'OPEN_SNACKBAR',
        content
      });
    
      setTimeout(
        () => dispatch({ type: 'CLOSE_SNACKBAR' }),
        4000
      );
    }
  }
}

export function useQueryCustom(query, { onSuccess, onError, ...options }) {
  const [func, { error }] = useManualQuery(query, options);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    let isSubscribed = true;
    func()
      .then(response => {
        if(isSubscribed) {
          if(typeof onSuccess === 'function') onSuccess(response.data);
          setData(response.data);
          setLoading(false);
        }
      })
      .catch(err => {
        if(isSubscribed) {
          if(typeof onError === 'function') onError(err);
          setLoading(false);
        }
      });
    
    return () => isSubscribed = false;
    // eslint-disable-next-line
  }, []);

  return {
    error,
    data,
    loading
  };
}